<template>
  <div v-if="!editMode">
    <form @submit.prevent="submitSupportDocument">
      <app-upload-support-document
        class="mb-4"
        v-for="(documents, name) in support_documents"
        :key="name"
        :title="name"
        :errors="errors"
        :documents="documents"
        @delete="deleteDocument"
        @changed="changed"
        @refresh="refresh"
        :type="type_support_documents"
        rule="all"
      />

      <app-button
        type="submit"
        class="mt-10"
        :showf70Icon="true"
        :loading="loading || mediaLoading"
      >
        {{ $t("general.continue") }}
      </app-button>
    </form>
  </div>
  <div v-else class="w-full space-y-5">
    <div v-for="(documents, name) in support_documents" :key="name">
      <p v-if="!$lodash.isEmpty(documents)" class="uppercase text-xs pb-3">
        {{ name?.split("_").join(" ") }}
      </p>
      <div
        v-for="(document, index) in documents"
        :key="index"
        class="
          py-2
          px-4
          flex flex-col
          sm:flex-row sm:space-x-3
          space-x-0
          sm:space-y-0
          space-y-3
          sm:items-center
          items-start
          justify-between
          hover:bg-gray-100
          border-b border-gray-200
        "
        :class="{ 'border-t': index == 0 }"
      >
        <div class="flex-1 flex items-left items-center space-x-5">
          <app-icon-jpg
            v-if="
              document.mime_type.includes('jpeg') ||
              document.mime_type.includes('jpg')
            "
          />
          <app-icon-png v-else-if="document.mime_type.includes('png')" />
          <app-icon-pdf v-else-if="document.mime_type.includes('pdf')" />
          <p class="text-sm">
            {{ document.file_name }}
          </p>
        </div>

        <div class="flex space-x-5">
          <p class="text-xs text-gray-500 mt-1">
            {{ Math.round(document.size / 1024) }} KB
          </p>
          <a
            :href="document.original_url"
            class="flex space-x-1 items-center cursor-pointer text-black"
            target="_blank"
          >
            <p class="text-sm">{{ $t("general.view") }}</p>
          </a>
          <a
            class="flex space-x-1 items-center cursor-pointer text-black"
            @click="download(document)"
          >
            <p class="text-sm">{{ $t("general.download") }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
   data() {
    return {
      files: {},
    };
  },
  props: ["menu_action", "edit_mode"],

  mounted() {
    this.$store.dispatch(
      "businessStore/typeBusinessSupportDocuments",
      this.$route.params.business_id
    );
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    errors() {
      return this.$store.getters["businessStore/errors"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    support_documents() {
      return this.$store.getters["businessStore/support_documents"];
    },

    type_support_documents() {
      return this.$store.getters["businessStore/type_support_documents"];
    },

    validate_support_documents() {
      return this.$store.getters["businessStore/validate_support_documents"];
    },

    business_id() {
      return this.$route.query.business_id;
    },

    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },

  methods: {
    async submitSupportDocument() {
      this.resetState();

      const result = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessSupportDocument",
        {
            business_id: this.business.id,
            documents  : this.files}
      );

      if (result.data != null) {
        this.refresh();
        this.editMode = true
        this.this.files = {};
      } else if (result.message) {
        this.$notify(
          {
            group: "error",
            title: result.message,
          },
          5000
        );
      }
    },

    async deleteDocument(document) {
      const confirm_delete = confirm(
        this.$t("business.support_documents.delete_confirm")
      );

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "businessStore/deleteBusinessSupportDocument",
           {
            business_id: this.business.id,
            document_id: document._id,
          }
        );
      }

      this.refresh();
    },
    changed(file, title) {
      Object.assign(this.files, { [title]: file });
    },

    download(media) {
      axios
        .get(media.original_url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: media.mime_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = media.file_name;
          link.click();
        })
        .catch((error) => {
          // if has been blocked by CORS redirect to original url
          window.location.href = media.original_url;
        });
    },

    resetState() {
      this.unsaved = false;
      this.$store.commit("businessStore/setErrorMessage");
    },
    refresh() {
      this.$store.dispatch(
        "businessStore/getBusinessSupportDocuments",
        this.business.id
      );
      this.$store.dispatch(
        "businessStore/validateBusinessSupportDocuments",
        this.business.id
      );
      this.$store.dispatch(
        "businessStore/typeBusinessSupportDocuments",
        this.business.id
      );
    },
  },
};
</script>